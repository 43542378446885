import { lazy } from "react";
import {
  BrowserRouter as Router,
  Route, Routes,
} from "react-router-dom";

const Settings = lazy(() => import("./pages/Settings"));
const LayoutPlatform = lazy(() => import("./components/LayoutPlatform"));
const ModuleList = lazy(() => import("./pages/moduleList"));
const ModuleItem = lazy(() => import("./pages/moduleItem"));

function ErrorBoundary() {
  return <div>Path is not defined</div>
}

export default function CustomRouter() {
  return (<Router>
    <Routes>
    <Route path="/" element={<LayoutPlatform />} errorElement={<ErrorBoundary/>}>
      <Route index element={<ModuleList />} />
      <Route path=":moduleId" element={<ModuleItem />} />
      <Route path="/settings" element={<Settings />} />
    </Route>
    </Routes>
  </Router>)
}
