import { createSlice } from "@reduxjs/toolkit";
import { fetchArticle, fetchEndArticle, fetchModules } from "../../services";

const platformSlice = createSlice({
  name: "platform",
  initialState: {
    menuOpen: true,
    loading: false,
    error: false,
    modules: null,
    item: null,
    paywallOpen: false,
  },
  reducers: {
    handleMenuOpen(state, { payload }) {
      state.menuOpen = payload;
    },
    handlePaywallOpen(state, { payload }) {
      state.paywallOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchModules.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.modules = payload?.data;
      })
      .addCase(fetchModules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchArticle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchArticle.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.item = payload?.data;
      })
      .addCase(fetchArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchEndArticle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEndArticle.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchEndArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const platformActions = platformSlice.actions;
export const platformReducer = platformSlice.reducer;
