import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";

import App from './App';
import {store} from "./store";
import "@splidejs/splide/dist/css/splide.min.css";
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

