export const URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.dev.teachmegrow.com/api/";

export const URL_GAME = process.env.REACT_APP_API_URL
  ? "https://game.teachmegrow.com/"
  : "https://dev.teachmegrow.com/";

export const StartupStages = {
  ru: {
    name: "Путь стартапа",
    fields: ["", ""]
  },
  eng: {
    name: "Startup Stages",
    fields:["FFF", "Angels", "preSeed", "Seed", "A", "B", "C", "D", "pre-IPO", "IPO"]
  }
}

export const Metrics = {
  ru: {
    name: "Метрики",
    fields: [
      {label: "UA", value: "ua"},
      {label: "C1", value: "c1", type: "%"},
      {label: "B", value: "b"},
      {label: "AVPrice", value: "avp", type: "$"},
      {label: "Cogs", value: "cogs", type: "$"},
      {label: "APC", value: "apc", type: "$"},
      {label: "ARPC", value: "arpc", type: "$"},
      {label: "ARPU", value: "arpu", type: "$"},
      {label: "CPA", value: "cpa", type: "$"},
      {label: "CM", value: "cm", type: "$"}
    ]
  },
  eng: {
    name: "Metrics",
    fields: [
      {label: "UA", value: "ua"},
      {label: "C1", value: "c1", type: "%"},
      {label: "B", value: "b"},
      {label: "AVPrice", value: "avp", type: "$"},
      {label: "Cogs", value: "cogs", type: "$"},
      {label: "APC", value: "apc", type: "$"},
      {label: "ARPC", value: "arpc", type: "$"},
      {label: "ARPU", value: "arpu", type: "$"},
      {label: "CPA", value: "cpa", type: "$"},
      {label: "CM", value: "cm", type: "$"}
    ]
  }
}

export const MainMetrics = {
  ru: ["UA", "C1", "AVPrice", "Cogs", "APC", "CPA", "Bugs"],
  eng: ["UA", "C1", "AVPrice", "Cogs", "APC", "CPA", "Bugs"]
}

export const StagesData = {
  cantEmployment: {
    eng: "No employment possibility",
    ru: "Нет возможности найма"
  },
  eng: [{
    number: 1,
    name: "FFF",
    description: {
      name: "Description",
      text: "At this very early stage, you rely on your " +
      "personal relationships to obtain funding. " +
      "You have nothing with which to entice " +
      "investors, so you go to your FFFs: " +
      "Friends, Fools, and Family. This is one of " +
      "a startup’s first rounds of funding. Your " +
      "objective is to attract your first ten users " +
      "and prove that your product or service " +
      "solves someone’s problem"
    },
    task: {
      name: "Round task",
      text: "Get your first ten" +
      "clients and prove " +
      "that your company " +
      "is solving " +
      "someone’s " +
      "problem."
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing"]
    },
    investors: {
      name: "Investors",
      value: "No"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 2,
    name: "Angels",
    description: {
      name: "Description",
      text: "Angel investors are often the main source of funding for many " +
        "startups that have created MVPs and attracted early adopters. These individuals invest their " +
        "own capital during a company’s early stages. In this round your goal is to attract 100 users, confirm that your sales " +
        "are not just random luck, and that youunderstand the challenges and problems your customers face — and solve them."
    },
    task: {
      name: "Round task",
      text: "Increase your " +
        "number of clients " +
        "to 100"
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 3,
    name: "preSeed",
    description: {
      name: "Description",
      text: "You proved to early investors that your product has potential. Now you are ready for a public beta release — a " +
        "massive launch of your product. You collected feedback from the first 100 users, tested hypotheses, built a development cycle," +
        " redesigned and performed testing. However, serial investors are still not sure if your product is scaleable. Your goal is to attract 500 users."
    },
    task: {
      name: "Round task",
      text: "Increase your " +
        "number of users " +
        "to 500"
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 4,
    name: "Seed",
    description: {
      name: "Description",
      text: "Your product has shown great scalability and attracted the attention of a group of investors. That being said, for further " +
        "scaling, it’s important to achieve positive unit economics. There is nothing more illusory to a startup than negative unit " +
        "economics, or selling a product for less than its variable cost. After all, growth is easy and success is “achieved”  if you " +
        "sell a dollar for ninety cents. Get 2,500 subscribers, achieve a positive contribution margin, and economic convergence. " +
        "Start attracting customers through paid channels."
    },
    task: {
      name: "Round task",
      text: "Increase the number of your customers to 2,500, while ensuring that your contribution margin is positive."
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 5,
    name: "A",
    description: {
      name: "Description",
      text: "Your unit economics is positive. Investors believe in your product. However, for further growth, the weekly " +
        "turnover should also be positive. This will prove to investors that you are capable of running the company cost-effectively " +
        "and that you deserve to be the CEO"
    },
    task: {
      name: "Round task",
      text: "Bring the number of your clients to 10,000, and the contribution margin to at least $100,000. Lastly, " +
        "you must also pass the breakeven point, that is, show a weekly income."
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 6,
    name: "B",
    description: {
      name: "Description",
      text: "Learning, course-correcting, growing! Your company is breaking even and has achieved positive unit economics. Still, " +
        "investors want to make sure that you’re economically viable and can attract more customers and become a top seller in the local market."
    },
    task: {
      name: "Round task",
      text: "Your goal is 50,000 customers, a contribution margin of at least $500,000, and a weekly income of more than $100,000. Keep scaling up and " +
        "earn your first $100,000!"
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 7,
    name: "C",
    description: {
      name: "Description",
      text: "You have achieved all your goals and hit the ceiling in your local market. It’s time to keep scaling and expanding to " +
        "international markets. But be careful: new markets present new unexpected problems!"
    },
    task: {
      name: "Round task",
      text: "Number of customers: 100,000 Contribution margin: at least $1,000,000 Weekly income: more than $500,000 Keep scaling and earn your first $500,000"
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 8,
    name: "D",
    description: {
      name: "Description",
      text: "You have successfully entered the international market. Your global market share, however, is not yet significant enough for your startup to be considered " +
        "by strategic investors. Prove to them that your international sales are sustainable and growing, and that your product is universal — not geographically limited."
    },
    task: {
      name: "Round task",
      text: "Number of customers: 500,000. Contribution margin: at least $10,000,000. Weekly income: >$5,000,000. Earn your first $5,000,000"
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 9,
    name: "pre-IPO",
    description: {
      name: "Description",
      text: "Your company is recognized as a leader  in the global market. You have offices in major cities all over the world and your " +
        "finances are stellar. However, preparing for an IPO requires operational decisions from a strong CEO. Get your company " +
        "ready without impeding your steady growth. Increase the number of your clients to 1,000,000, expand the contribution margin to at least " +
        "$15,000,000, and earn more than $10,000,000 a week. Remember that to begin the IPO process, you first need to partner with an investment bank or a " +
        "large fund."
    },
    task: {
      name: "Round task",
      text: "Number of customers: 1,000,000. Contribution margin: at least $15,000,000. Weekly income: >$10,000,000. Begin IPO preparations. " +
        "Choose and partner with an investment bank or a large fund."
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }, {
    number: 10,
    name: "IPO",
    description: {
      name: "Description",
      text: "IPO"
    },
    task: {
      name: "Round task",
      text: "Final goal: 5,000,000 customers. Contribution margin: > $75,000,000. Weekly income: > $50,000,000"
    },
    team: {
      name: "Employees",
      values: ["CEO", "Developer", "Marketing", "Designer", "QA", "Manager"]
    },
    investors: {
      name: "Investors",
      value: "Yes"
    },
    mentors: {
      name: "Mentors",
      value: "Yes"
    }
  }]
}

export const TurnButtonData = {
  eng: {
    title: "Make a turn",
    text: "Income (this turn)"
  },
  ru: {
    title: "Сделать ход",
    text: "Доход за ход"
  }
}

export const NoAvailableTasksData = {
  eng: {
    "AllNoFilters": "Currently there are no available tasks. Press on Brainstorm button to generate new ones",
    "ProgressNoFilters": "Currently there are no active tasks. Choose one from “All tasks” section or generate new ones using Brainstorm session.",
    "AllFilters": "Currently there are no available tasks for selected filters.",
    "ProgressFilters": "Currently there are no active tasks for selected filters."
  }
}
