import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {URL} from "../constants";

export const Api = axios.create({
   withCredentials: true,
   baseURL: URL
})

export const fetchModules = createAsyncThunk(
  "education/modules", async () => {
    const resp = await Api.get("education/modules")
    return resp
  }
)

export const fetchEndArticle = createAsyncThunk(
  "education/end-article", async (body) => {
    const resp = await Api.post("education/article", body)
    return resp
  }
)

export const fetchArticle = createAsyncThunk(
  "education/article", async (id) => {
    const resp = await Api.get(`education/article/${id}`)
    return resp
  }
)

export const fetchSubscribe = createAsyncThunk(
  "payments/subscribe", async () => {
    const resp = await Api.get("payments/subscribe")
    return resp
  }
)

export const fetchLifetimeAccess = createAsyncThunk(
  "payments/lifetimeAccess", async (body) => {
    const resp = await Api.post("payments/lifetimeAccess", body)
    return resp
  }
)