import {useDispatch} from "react-redux";
import {
  fetchModules,
  fetchEndArticle,
  fetchArticle,
  fetchSubscribe,
  fetchLifetimeAccess
} from "../services";
import {bindActionCreators} from "@reduxjs/toolkit";
import {platformActions} from "../store/platform/platformSlice";

const actions = {
  fetchModules,
  fetchEndArticle,
  fetchArticle,
  fetchSubscribe,
  fetchLifetimeAccess,
  ...platformActions
}

export const useActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(actions, dispatch)
}
