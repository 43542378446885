import { ToastContainer, Zoom } from 'react-toastify';
import { Suspense } from "react";

import  RouterComponent  from "./router"
import Loader from "./components/Modal/loader";
import {Api} from "./services";
import {useActions} from "./hooks/actions";

function App() {
  const renderLoader = () => <Loader open={true}/>;

  const { handlePaywallOpen } = useActions()

  Api.interceptors.response.use(
    response => {if(response) {
      handlePaywallOpen(false)
      return response
    }},
    error => {
      if(error.response.status === 403) {
        handlePaywallOpen(true)
      }
      return error
    })

  return (
      <Suspense fallback={renderLoader()}>
        <RouterComponent/>
        <ToastContainer autoClose={1000} hideProgressBar transition={Zoom}/>
      </Suspense>
  );
}

export default App;
